import React from "react";
import MNavBar from "../../Component/Mobile/Common/MNavBar/MNavBar";
import { Box, Tab } from "@mui/material";
import Slider from "react-slick";
import CustomButton from "../../Component/InputFields/CustomButton/CustomButton";
import Grid from "@mui/material/Unstable_Grid2";
import "./MobHome.scss";
import CustomCard from "../../Component/Desktop/Common/CustomCard/CustomCard";
import DoneIcon from "@mui/icons-material/Done";
import FAQ from "../../Component/FAQ/FAQ";
import MFooter from "../../Component/Mobile/Common/MFooter/MFooter";

function MobHome() {
  const [activeBtn1, setActiveBtn1] = React.useState<boolean>(true);
  const [activeBtn2, setActiveBtn2] = React.useState<boolean>(false);

  const toggleBtn1 = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveBtn1(!activeBtn1);
    setActiveBtn2(false);
  };
  const toggleBtn2 = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveBtn2(!activeBtn2);
    setActiveBtn1(false);
  };

  const blogSlidersettings = {
    infinite: true,
    slidesToShow: 2,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partnerSlidersettings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    margin: 24,
  };
  const reviewSlidersettings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    margin: 24,
  };
  const sponsorSlidersettings = {
    className: "center",
    infinite: true,
    slidesToShow: 2,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const headerSlidersettings = {
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
  };
  const featureSlidersettings = {
    infinite: true,
    slidesToShow: 2,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const headerSliderArray = [
    {
      sliderBgclass: "car-sb",
      slider_img_icon: "./images/car-slider-icon.svg",
      productImg: "./images/car-sliderbox.png",
      tagline: "Let’s find the Best Insurance that fit your needs",
      buttonText: "View Term Plan",
    },
    {
      sliderBgclass: "car-sb",
      slider_img_icon: "./images/car-slider-icon.svg",
      productImg: "./images/familycover.png",
      INS_Type_tag: "Car Insurance",
      tagline: "Health Insurance + Unlimited Limited Benefits at low Cost ",
      buttonText: "View Health Plan",
    },
  ];
  const featureSliderArray = [
    {
      slider_img_icon: "./images/homeinsuranceicon.svg",
      iconText: "Business for business",
      tagline: (
        <>
          One Stop for <span>Health Insurance (Tab for become POSP)</span>
        </>
      ),
      bgimg: `url(${process.env.PUBLIC_URL}/images/homeinsbg.svg)`,
      bgColor: "rgba(255, 165, 90, 1)",
    },
    {
      slider_img_icon: "./images/policybazaricon.svg",
      // iconText: "Health - 1 Crore Health Insurance Now at Affordable Price",
      tagline: (
        <>
          Health - 1 Crore Health Insurance <span>Now at Affordable Price</span>
        </>
      ),
      bgimg: `url(${process.env.PUBLIC_URL}/images/policybg.svg)`,
      bgColor: "rgba(184, 213, 255, 1)",
    },
    {
      slider_img_icon: "./images/askpbicon.svg",
      iconText: (
        <>
          Before you leave!
          <span
            style={{ display: "block", marginTop: "8px", fontSize: "16px" }}
          >
            Don't let medical bills drain your parent's savings
          </span>
        </>
      ),
      subtxt: "",
      tagline: (
        <>
          Buy ₹5 Lakh health cover starting just
          <span>
            <strong>@ ₹1325/month*</strong>
          </span>
        </>
      ),
      bgimg: `url(${process.env.PUBLIC_URL}/images/askpbbg.svg)`,
      bgColor: "rgba(255, 207, 168, 1)",
    },
  ];
  const achivements = [
    {
      areaicon: "./images/consumer-icon.svg",
      achieveHead: "50k+",
      achieveSub: "Consumers",
    },
    {
      areaicon: "./images/advisor-icon.svg",
      achieveHead: "1000+",
      achieveSub: "Strong advisor Network",
    },
    {
      areaicon: "./images/globe-icon.svg",
      achieveHead: "750+",
      achieveSub: "spread Across Districts in India",
    },
  ];
  const newFeatures = [
    {
      featureHead: "Expert Advice",
      featureDesc: "Advisors you can count on",
    },
    {
      featureHead: "Claim Assistance",
      featureDesc:
        "Get 24x7 claim assistance from our best advisors that will help you make your claim process hassle-free",
    },
    {
      featureHead: "Get Instant Quotes",
      featureDesc:
        "Get access to instant quotes from any insurance company in just a few seconds",
    },
    {
      featureHead: "35+ Insurers",
      featureDesc:
        "Buy the best insurance plans from multiple insurers at affordable premiums",
    },
  ];
  const extraFeatures = [
    "Earn Stable Income",
    "Work From Home",
    "Get POSP Certification",
    "Learn From Industry Experts",
    "Digital Passbook to Track Your Business",
    "No Investment Required",
    "Learn about POSP program",
  ];
  const webFeature = [
    {
      featureHead: "Relationship Manager",
      featureDesc:
        "Boost your revenue with the help of relationship manager that helps you run your business smoothly",
    },
    {
      featureHead: "iMeeting",
      featureDesc:
        "Digitally connect with Health and Life Expert to solve your customer queries",
    },
    {
      featureHead: "Training",
      featureDesc:
        "Providing training sessions on company policies, products, sales techniques, and compliance requirements.",
    },
    {
      featureHead: "Advisor Connect",
      featureDesc: "Connect with customers digitally near your location",
    },
  ];
  const sponsors = [
    "./images/sponsor1.jpg",
    "./images/sponsor2.jpg",
    "./images/sponsor3.jpg",
    "./images/sponsor4.jpg",
  ];
  const whyChoose = [
    {
      icon: "./images/physiDigital-icon.svg",
      queryHead: "Digital Access",
      queryDesc:
        "Policyholders can access their digital passbook anytime and anywhere using a smartphone, tablet, or computer, ensuring they have important policy information at their fingertips.",
    },
    {
      icon: "./images/claim-icon.svg",
      queryHead: "Customer Support",
      queryDesc:
        "Growwinsurance provides strong customer support through multiple channels, ensuring that customers receive timely help and answers to their queries.",
    },
    {
      icon: "./images/bestProduct-icon.svg",
      queryHead: "Expert Advice",
      queryDesc:
        "Growwinsurance provides access to insurance experts who offer personalized advice and assistance, helping customers choose the right policy based on their specific requirements.",
    },
    {
      icon: "./images/customerSupport-icon.svg",
      queryHead: "User-Friendly Portal",
      queryDesc:
        "The platform is designed to be easy to use, enabling customers to navigate through various insurance options, understand their features, and compare them effortlessly.",
    },
  ];
  const insuranceSteps = [
    {
      stepIcon: "./images/choose-product.svg",
      stepHead: "Choose Product",
      stepDesc: "Based on your needs , Select the product.",
    },
    {
      stepIcon: "./images/fill-details.svg",
      stepHead: "Fill Details",
      stepDesc:
        "Fill your details and get the insurance policy premium quotes with in seconds.",
    },
    {
      stepIcon: "./images/make-payment.svg",
      stepHead: "Make Payment",
      stepDesc: "Select the payment option and complete the payment",
    },
  ];
  const reviews = [
    {
      clientimg: "./images/client1.png",
      review:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum.",
      name: "Sneha Goyal",
      role: "Business Entrepreneur",
    },
    {
      clientimg: "./images/client2.png",
      review:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum.",
      name: "Satya Anand",
      role: "Business Entrepreneur",
    },
    {
      clientimg: "./images/client3.png",
      review:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum.",
      name: "Rucha Sakya",
      role: "Software Professional",
    },
  ];
  const partners = [
    {
      img1: "./images/insurers/aditya_birla_health.svg",
      img2: "./images/insurers/AEGON-Religare-Life-Insurance-Company-Limited.svg",
      img3: "./images/insurers/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
      img4: "./images/insurers/kotak_general_insurance.svg",
      img5: "./images/insurers/Bajaj-Allianz-Life-Insurance-Company-Limited.svg",
      img6: "./images/insurers/Magma-HDI-General-Insurance-Company-Limited.svg",
      img7: "./images/insurers/Max-Life-Insurance-Co-Ltd.svg",
      img8: "./images/insurers/sbi-life-insurance.svg",
    },
    {
      img1: "./images/insurers/aditya_birla_health.svg",
      img2: "./images/insurers/AEGON-Religare-Life-Insurance-Company-Limited.svg",
      img3: "./images/insurers/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
      img4: "./images/insurers/kotak_general_insurance.svg",
      img5: "./images/insurers/Bajaj-Allianz-Life-Insurance-Company-Limited.svg",
      img6: "./images/insurers/Magma-HDI-General-Insurance-Company-Limited.svg",
      img7: "./images/insurers/Max-Life-Insurance-Co-Ltd.svg",
      img8: "./images/insurers/sbi-life-insurance.svg",
    },
  ];
  const blogPost = [
    {
      blogimg: "./images/healthIns.svg",
      blogtitle: "Health Insurance",
      blogDes:
        "it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using ",
    },
    {
      blogimg: "./images/carIns.svg",
      blogtitle: "Car Insurance",
      blogDes:
        "it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using  ",
    },
    {
      blogimg: "./images/termIns.svg",
      blogtitle: "Term Insurance",
      blogDes:
        "it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using ",
    },
  ];
  const faqs = [
    {
      title: "What is health insurance Plan?",
      desc: "Health insurance is a type of insurance that provides coverage for medical costs incurred due to an injury or illness. These expenses can be related to doctor's consultation fees, hospitalization costs and medicine costs etc.",
    },
    {
      title: "What is health insurance premium?",
      desc: "Premium is what you pay to buy health insurance, and it is the amount you agree to pay to the health insurance company in return for the health insurance plan.",
    },
    {
      title: "What happens if I fail to pay my health insurance premium?",
      desc: "Your health policy will be cancelled if you cannot pay your health insurance premium. Health insurance companies offer you a grace period of 15 to 30 days if you don't pay the premium on time. You can renew your health insurance plan within this grace period, but if you miss this chance as well, you risk losing your health plan.",
    },
    {
      title: "Does health insurance plan cover ayurveda & other treatments? ",
      desc: "Yes, many health insurers offer medical coverage for AYUSH treatments in India. However, a health plan that covers alternative therapies like Ayurveda, homoeopathy, and others might have an upper limit on the coverage limit.",
    },
    {
      title:
        "Does health insurance offer coverage for robotic surgery and modern treatment?",
      desc: "As per the Insurance Regulatory and Development Authority of India (IRDAI) in 2019, Every health insurance company is required to insure robotic and modern treatments. Therefore, all insurance companies provide coverage for it with some sub-limits and conditions.",
    },
    {
      title: "What is cumulative bonus in health insurance plan.",
      desc: "Cumulative bonus in health insurance is a financial benefit you receive as a reward for not making any claim in a single policy year.",
    },
    {
      title:
        "What are the different types of health insurance plans available in the market?",
      desc: (
        <>
          "Health Insurance comes in various variants. Below mentioned is the
          list of different types of health insurance plans available in the
          market:" <br />• Individual Health Insurance, • Family Floater Health
          Insurance, • Senior Citizen Health Insurance, • Critical Illness
          Health Insurance• Top-up Health Insurance
        </>
      ),
    },
    {
      title: "Will buying health insurance qualify me for tax benefits?",
      desc: "Yes, you can claim tax benefits under Section 80D of the Income Tax Act of 1961 if you have a health insurance plan.",
    },
    {
      title: "Why should I buy health insurance?",
      desc: "It would be best if you bought health insurance so that you don't have to use up your entire life savings to pay for medical expenses in case of an emergency.",
    },
    {
      title: "What is the maximum number of claims allowed over a year?",
      desc: "The policyholder can make any number of claims yearly, but the claim amount must be within the sum insured.",
    },
    {
      title: "How much does health insurance cost in india?",
      desc: "There are many different policies that you can buy, and there are many conditions that affect a health insurance policy; these include age, gender, medical history, lifestyle, etc.)",
    },
    {
      title: "What is the right age to buy health insurance policy?",
      desc: "There is no right age to buy a policy. But the earlier you buy the policy, the lesser the premium. The reason is that when you are young, there is a lesser risk of illness as compared to someone who is in their 50s or 60s",
    },
    {
      title: "What is copayment.",
      desc: "Copayment is a certain percentage of the claim that the insured agrees to pay along with the insurance company. The insurer then pays the remaining claim amount.",
    },
    {
      title:
        "What are pre and post-hospitalization expenses in health insurance?",
      desc: "Pre-hospitalization expenses refer to the medical expenses incurred before getting admitted to a hospital. Post-hospitalization expenses refer to the cost of follow-up tests and consultation treatment charges incurred after getting discharged from the hospital.",
    },
  ];
  return (
    <Box className="mhomepage-wrapper">
      <MNavBar />
      <Box className="product-section section">
        <Grid columnSpacing={3} container className="row">
          <Grid xs={12}>
            <h1 className="mainHeading">
              Find the <span>best insurance</span> & financial products that fit
              your needs
            </h1>
            <Slider {...headerSlidersettings}>
              {headerSliderArray?.map((data, index) => (
                <Box key={index}>
                  <Box
                    className={`sliderBox ${data.sliderBgclass}`}
                    margin="0px 6px"
                  >
                    <Grid container columnSpacing={3}>
                      <Grid xs={6}>
                        <Box className="sliderInfo">
                          <p className="tagline">{data.tagline}</p>
                          <CustomButton
                            className="primaryBtn mt-2 small"
                            text={data.buttonText}
                            fullWidth={false}
                            variant="contained"
                          />
                        </Box>
                      </Grid>
                      <Grid xs={6}>
                        <Box className="sliderImg">
                          <img
                            src={data.productImg}
                            width="100%"
                            alt="img"
                            style={{ maxWidth: "389px" }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Slider>
            <Box
              display={"flex"}
              gap={"12px"}
              alignItems={"center"}
              className="productIcon"
            >
              <Box className="ip_box">
                <Box className="imgbox">
                  <img
                    src="./images/healthcare.svg"
                    alt=""
                    style={{ maxWidth: "114px", maxHeight: "76px" }}
                  />
                </Box>
                <p>Health Insurance</p>
              </Box>
              <Box className="ip_box">
                <Box className="imgbox">
                  <img
                    src="./images/term.svg"
                    alt=""
                    style={{ maxWidth: "114px", maxHeight: "76px" }}
                  />
                </Box>
                <p>Term Insurance</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="feature-section section">
        <Grid className="row" container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="secHead">
              <h4>
                It is a long established fact that a reader will be distracted
                by the readable
              </h4>
            </Box>
            <Slider {...featureSlidersettings}>
              {featureSliderArray?.map((data, index) => (
                <Box className="featureBox">
                  <Box
                    className="featureSlider"
                    margin="0 5px"
                    sx={{
                      backgroundImage: data.bgimg,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundColor: data.bgColor,
                    }}
                  >
                    <img
                      src={data.slider_img_icon}
                      style={{ maxWidth: "96px", maxHeight: "36px" }}
                    />
                    <p>{data.iconText}</p>
                    <h4>{data.tagline}</h4>
                    <h3 className="medium">{data.subtxt}</h3>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>
      <Box className="expert-section section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="secHead">
              <h4 className="mb-6">
                <span>Get expert assistance</span> for all your insurance needs
                anywhere, anytime
              </h4>
            </Box>
          </Grid>
        </Grid>

        <Grid columnSpacing={3} container alignItems="center" className="row">
          <Grid xs={12} className="featuresRow">
            {achivements.map((data, index) => {
              return (
                <ul className="achievements" key={index}>
                  <li>
                    <img src={data.areaicon} alt={data.achieveHead} />
                  </li>
                  <li>
                    <h4>
                      {data.achieveHead}
                      <span>{data.achieveSub}</span>
                    </h4>
                  </li>
                </ul>
              );
            })}
          </Grid>
          <Grid xs={12}>
            <Box textAlign="center">
              <img
                src="./images/experts-profile.svg"
                alt="expertImg"
                style={{ maxWidth: "344px", margin: "24px 0" }}
              />
            </Box>
          </Grid>
          <Grid xs={12} className="featuresRow">
            {newFeatures.map((feature, index) => {
              let count = index + 1;
              return (
                <ul className="newFeature" key={index}>
                  <li className="fcount">
                    {count.toString().padStart(2, "0")}
                  </li>
                  <li>
                    <h6>{feature.featureHead}</h6>
                    <h5>{feature.featureDesc}</h5>
                  </li>
                </ul>
              );
            })}
          </Grid>
        </Grid>
      </Box>
      <Box className="earnextra-section section">
        <Grid columnSpacing={3} container alignItems="center" className="row">
          <Grid xs={12} md={6} className="extrabox">
            <Box className="secHead">
              <p className="sup">POSP ADVISOR</p>
              <h4>
                Earn <span>Extra Income</span> with Us
              </h4>
              <p className="subHead lightp">
                Now, you can become our POSP advisor and enjoy the benefits
              </p>
            </Box>
            <Box>
              <ul className="extraFeatureList">
                {extraFeatures.map((data, i) => {
                  return (
                    <li key={i}>
                      <DoneIcon />
                      {data}
                    </li>
                  );
                })}
              </ul>
            </Box>
            <Box>
              <CustomButton
                text="Become a POSP Advisor"
                variant="contained"
                className="primaryBtn small"
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} textAlign="center">
            <img
              src="./images/extra-income.svg"
              alt="extra-income"
              style={{ maxWidth: "350px" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="webfeature-section section">
        <Box>
          <Grid columnSpacing={3} container alignItems="center" className="row">
            <Grid xs={12} className="featuresWeb">
              <Box className="secHead">
                <h4 style={{ textAlign: "left" }}>
                  Web <span>features</span>
                </h4>
              </Box>
              <Box>
                {webFeature.map((data, i) => {
                  return (
                    <Box key={i} className="webFeatureList">
                      <h3 className="medium main">{data.featureHead}</h3>
                      <h3 className="medium desc">{data.featureDesc}</h3>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box className="webFeatureimg">
                <img
                  src="./images/web-feature.svg"
                  alt="webfeatureimg"
                  style={{ maxWidth: "391px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="sponsored-section section">
        <Box className="row">
          <Box className="secHead">
            <h4>Sponsored</h4>
          </Box>
          <Box className="sponsors">
            <Slider {...sponsorSlidersettings}>
              {sponsors?.map((data, index) => (
                <Box>
                  <Box className="sliderImg" key={index} margin="0px 29px">
                    <img
                      src={data}
                      width="100%"
                      alt="img"
                      style={{ maxWidth: "382px" }}
                    />
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Box>
      <Box className="customerchoose-section section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <Box className="secHead">
              <h4 className="mb-3">
                Why customers should choose <span>groww Insurance?</span>
              </h4>
              <p className="lightp">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a
                galley.
              </p>
            </Box>
            <Box className="query">
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} gap={3}>
                {whyChoose.map((data, i) => {
                  return (
                    <Grid xs={12} key={i}>
                      <ul className="customerQuery">
                        <li className="queryimg">
                          <img
                            src={data.icon}
                            alt={data.queryHead}
                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                          />
                        </li>
                        <li>
                          <h6>{data.queryHead}</h6>
                          <p className="lightp">{data.queryDesc}</p>
                        </li>
                      </ul>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="buyInsurance-section section">
        <Grid className="row" container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="secHead">
              <h4>
                <span>Buying Insurance</span> Made Easy with Three steps
              </h4>
            </Box>
            <Box>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="row"
              >
                {insuranceSteps.map((data, index) => (
                  <Grid sm={12} md={4} key={index}>
                    <Box className="stepsbox">
                      <p className="stepNo">{index + 1}</p>
                      <img
                        src={data.stepIcon}
                        alt={data.stepHead}
                        style={{ maxWidth: "100px", maxHeight: "92px" }}
                      />
                      <h5>{data.stepHead}</h5>
                      <p className="subHead lightp">{data.stepDesc}</p>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="testimonial-section section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="secHead">
              <p className="sup">Testimonials</p>
              <h4>
                Explore <span>Clients Review</span>
              </h4>
            </Box>
            <Box className="testimonials">
              <Slider {...reviewSlidersettings}>
                {reviews?.map((data, index) => (
                  <Box className="sliderbox" key={index}>
                    <Box className="reviewSlides" style={{ margin: "0 12px" }}>
                      <p className="review">{data.review}</p>
                      <ul className="clientBox">
                        <li>
                          <img src={data.clientimg} alt={data.name} />
                        </li>
                        <li>
                          <h3 className="bold">{data.name}</h3>
                          <p>{data.role}</p>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="ourPartner-section section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <Box className="secHead">
              <h4>
                Our <span>Partners</span>
              </h4>
            </Box>
            <Slider {...partnerSlidersettings}>
              {partners.map((data, i) => {
                return (
                  <Box style={{ margin: "0 12px" }}>
                    <ul className="partnerslist">
                      <li>
                        <img
                          src={data.img1}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img2}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img3}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img4}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img5}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img6}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img7}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                      <li>
                        <img
                          src={data.img8}
                          style={{ maxWidth: "218px", maxHeight: "212px" }}
                        />
                      </li>
                    </ul>
                  </Box>
                );
              })}
            </Slider>
          </Grid>
        </Grid>
      </Box>
      <Box className="blog-section section">
        <Box className="row blogspost">
          <Box className="secHead">
            <h4>
              Our <span>Blogs</span>
            </h4>
          </Box>
          <Slider {...blogSlidersettings}>
            {blogPost.map((data, i) => {
              return <CustomCard key={i} {...data} />;
            })}
          </Slider>
        </Box>
      </Box>
      <Box className="faq-section section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="secHead">
              <h4>
                <span>Frequently Asked Questions</span> (FAQ)
              </h4>
              <Box className="headBtn">
                <CustomButton
                  variant="contained"
                  className={activeBtn1 ? "primaryBtn light" : "secondaryBtn"}
                  text="Health Insurance"
                  onClick={toggleBtn1}
                />
                <CustomButton
                  variant="outlined"
                  className={activeBtn2 ? "primaryBtn light" : "secondaryBtn"}
                  text="Life Insurance"
                  onClick={toggleBtn2}
                />
              </Box>
            </Box>
            <FAQ data={faqs} />
          </Grid>
        </Grid>
      </Box>
      <MFooter />
    </Box>
  );
}

export default MobHome;
