import React from "react";
import { Box, Link, Tab } from "@mui/material";
import useMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Slider from "react-slick";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import FAQ from "../../../Component/FAQ/FAQ";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import ResTextField from "../../../Component/InputFields/ResTextFields/ResTextField";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";

function BecomeAdvisor() {
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    pincode: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    pincode: { value: "", warn_status: false },
  });
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  const [activeBtn1, setActiveBtn1] = React.useState<boolean>(true);
  const [activeBtn2, setActiveBtn2] = React.useState<boolean>(false);

  const toggleBtn1 = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveBtn1(!activeBtn1);
    setActiveBtn2(false);
  };
  const toggleBtn2 = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveBtn2(!activeBtn2);
    setActiveBtn1(false);
  };

  const adviseSlidersettings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
  };

  const faqs = [
    {
      title: "Benefits of Becoming Groww Insurance’s POSP Insurance Agent?",
      desc: (
        <>
          <p>
            The benefits of becoming a POSP Insurance Agent with Groww Insurance
            are:
          </p>
          <p>
            Hassle free Online Process: Once you become a POSP Insurance Agent
            of Groww Insurance, you are all set to sell Car, Two-wheeler,
            Health, Life & Travel Insurance policies online.
          </p>
          <p>
            Multiple Choices: As Groww Insurance is partnered with multiple
            insurance providers which gives an opportunity to work for more than
            one insurance provider to its POSP Insurance Agent.
          </p>
          <p>
            Extra Income: The easiest way to earn extra income without putting
            much of your efforts is by becoming a POSP Insurance Agent with
            Groww Insurance and earn as much as you want to.
          </p>
          <p>
            Zero Investment: All you need to have is a smartphone/Laptop with
            internet connectivity.
          </p>
        </>
      ),
    },
    {
      title: "How much one can earn as a Groww Insurance POSP Insurance Agent?",
      desc: "It totally depends on person to person. If you can understand people’s requirement and advise them to buy the product that is better for them then sky is the limit. The more efforts you put in your work, the more you can earn.",
    },
    {
      title:
        "What is the Educational Qualification to become a Groww Insurance POSP Insurance Agent?",
      desc: "To become a POSP Insurance Agent, the minimum educational qualification criteria is 10th pass.",
    },
    {
      title:
        "Which all Insurance Companies' policy can a Groww Insurance POSP Insurance Agent sell?",
      desc: "There are multiple companies we have on our platform across the mentioned insurance products i.e. Motor Insurance & Health Insurance. So, a POSP Insurance Agent of Groww Insurance can sell policies of these categories offered by multiple Insurance companies.",
    },
    {
      title: "What happens when you fail the POSP Insurance Agent exam?",
      desc: "You can always refer study material (available on the internet) and take the exam again. There is no cost involved here and multiple attempts of exam can be taken without any additional cost or training.",
    },
    {
      title:
        "What is the training duration and syllabus for becoming Groww Insurance POSP Insurance Agent?",
      desc: "For becoming a POSP Insurance Agent with Groww Insurance, one must undergo an online training of 15 hours. Training consists of 5 Modules, and on each module, one must spend an average time of 3 hour to move to the next module. The training syllabus covers all the basic and advanced information on Insurance.",
    },
    {
      title: "Why to become a POSP Insurance Agent?",
      desc: (
        <>
          There are many reasons to become a POSP Insurance Agent, few of them
          are listed below
          <ul>
            <li>
              • Be your own Boss: By becoming a POSP Insurance Agent, you need
              not to follow orders, in this case you will be your own boss.
            </li>
            <li>
              • No fixed working hours: There will be no fixed working hours.
              The more you spend time on your work, the more you can earn.
            </li>
            <li>
              • Extra Earning: You can work part time and can earn extra income
              above your monthly earnings.
            </li>
            <li>
              • No need to go anywhere: You can issue Insurance policies online.
            </li>
            <li>
              • Zero Investment: All you need to have is a smartphone with
              internet connectivity.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is POSP?",
      desc: "POSP is an online platform for insurance producers (agents/brokers) to manage their business with our company.",
    },
    {
      title: "How do I access POSP?",
      desc: "You can access POSP through our website, using your unique login credentials (username and password).",
    },
    {
      title: "What can I do on POSP?",
      desc: (
        <>
          You can: <br />+ View and manage your client list <br />+ Submit new
          business applications <br />+ Check policy status and details <br />+
          View commissions and statements <br />+ Access marketing materials and
          sales tools
        </>
      ),
    },
    {
      title: "Is POSP secure?",
      desc: "Yes, POSP is a secure platform, using encryption and secure servers to protect your data and client information.",
    },
    {
      title: "How do I get trained on POSP?",
      desc: "We offer online training and support resources, including webinars, user guides, and FAQs. Contact our support team for assistance.",
    },
    {
      title: "What if I need help with POSP?",
      desc: "Our support team is available to assist you, via phone, email, or online chat.",
    },
    {
      title: "Can I access POSP on my app device/Laptop?",
      desc: "Yes, POSP is mobile-friendly and can be accessed on your smartphone or Laptop.",
    },
  ];

  const Carfaqs = [
    {
      title: "Lorem Ipsum is simply dummy2",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras placerat velit in odio ultricies, in accumsan est convallis.",
    },
    {
      title: "Lorem Ipsum is simply dummy2",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras placerat velit in odio ultricies, in accumsan est convallis.",
    },
    {
      title: "Lorem Ipsum is simply dummy2",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras placerat velit in odio ultricies, in accumsan est convallis.",
    },
    {
      title: "Lorem Ipsum is simply dummy2",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras placerat velit in odio ultricies, in accumsan est convallis.",
    },
    {
      title: "Lorem Ipsum is simply dummy2",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras placerat velit in odio ultricies, in accumsan est convallis.",
    },
  ];

  const benefits = [
    {
      icon: "./images/zero-invest.svg",
      heading: "Zero Investment Business Opportunity",
    },
    {
      icon: "./images/free-posp.svg",
      heading: "Free POSP Certificate under IRDAI Guidelines",
    },
    {
      icon: "./images/earn-monthly.svg",
      heading: "Earn Monthly Attractive Pay-outs",
    },
    {
      icon: "./images/digital-passbook.svg",
      heading: "Digital Passbook to Track Your Business",
    },
    {
      icon: "./images/support.svg",
      heading: "Dedicated Relationship Manager Support",
    },
  ];
  const criteria = [
    "Get started with the training process, pass your exam and certification process for various insurance products and start selling",
    "You must have at least a 10th pass",
    "You need documents like ID Proof, PAN card, address proof, recent photographs and an essential exam certificate.",
    "Attend verified training to become a POSP advisor conducted by the company and pass the exam conducted by IRDAI guidelines",
    "Own an approved certification by IRDAI post-training",
  ];

  const advisor = [
    {
      heading: "Create Account",
      desc: "We're at the forefront of transforming industries with cutting-edge technology, seamlessly integrating innovative solutions to redefine how you operate.",
    },
    {
      heading: "Document Submission",
      desc: "Submit KYC documents such as an Aadhaar card, PAN Card, 10th passing certificate, etc.",
    },
    {
      heading: "Document Verification",
      desc: "GrowwInsurance Quality Check team will verify the documents.",
    },
    {
      heading: "Online Training & Examination",
      desc: "Get started with the training process, pass your exam and certification process for various insurance products and start selling",
    },
  ];

  const portfolio = [
    {
      icon: "./images/health-icon.svg",
      heading: "Health Insurance",
      desc: "Sell Health Insurance policies and make your customers stress-free during medical emergencies.",
    },
    {
      icon: "./images/motor-icon.svg",
      heading: "Motor Insurance",
      desc: "Sell Health Insurance policies and make your customers stress-free during medical emergencies.",
    },
  ];

  const whychooseList = [
    "POSP continues to look into many alternatives and possibilities to pursue bigger objectives",
    "POSP can speak with several insurance companies to compare options and offer customers the best policy per their requirements. This entitlement is available for all IRDA-approved policies.",
    "GrowwInsurance offers a user-friendly web interface to speed up customer service delivery.",
    "You can improve and maintain customer relationships to encourage renewals as the sole point of contact between the customer and the insurance company.",
  ];

  const techAdvise = [
    {
      techimg: "./images/tech1.svg",
      techname: "Vishal Tyagi",
      designation: "(Groww Insurance, POSP Advisor)",
      advise:
        "Groww Insurance is a great brand to work with, I am amazed by Groww Insurance support and training programs. These programs helped me a lot in enhancing my skills as a POSP agent. Now, I can find and suggest the best possible insurance plans to the customers.",
    },
    {
      techimg: "./images/tech1.svg",
      techname: "Vishal Tyagi",
      designation: "(Groww Insurance, POSP Advisor)",
      advise:
        "Groww Insurance is a great brand to work with, I am amazed by Groww Insurance support and training programs. These programs helped me a lot in enhancing my skills as a POSP agent. Now, I can find and suggest the best possible insurance plans to the customers.",
    },
    {
      techimg: "./images/tech1.svg",
      techname: "Vishal Tyagi",
      designation: "(Groww Insurance, POSP Advisor)",
      advise:
        "Groww Insurance is a great brand to work with, I am amazed by Groww Insurance support and training programs. These programs helped me a lot in enhancing my skills as a POSP agent. Now, I can find and suggest the best possible insurance plans to the customers.",
    },
    {
      techimg: "./images/tech1.svg",
      techname: "Vishal Tyagi",
      designation: "(Groww Insurance, POSP Advisor)",
      advise:
        "Groww Insurance is a great brand to work with, I am amazed by Groww Insurance support and training programs. These programs helped me a lot in enhancing my skills as a POSP agent. Now, I can find and suggest the best possible insurance plans to the customers.",
    },
  ];

  const isMobile = useMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="advisor-header res_Section">
        <Grid
          className="row"
          container
          columnSpacing={3}
          rowSpacing={{ xs: 3 }}
        >
          <Grid md={7} sm={7}>
            <h1 className="mb-3">
              Become POSP <span>Insurance & Financial Advisor</span> Earn
              Monthly Commissions
            </h1>
            <p className="h3-medium lightp">
              Earn Big With Zero Investment By Joining Our Partner Community
            </p>
            <Box className="imgbox mt-6" textAlign="center">
              <img
                src="./images/advisor-header-img.svg"
                alt="advisorimg"
                style={{ maxWidth: "368px" }}
              />
            </Box>
          </Grid>
          <Grid md={5} sm={5} xs={12}>
            <Box className="formbox">
              <h4>Join Our Partner Program</h4>
              <form className="advisorForm">
                <RKTextField
                  class_name="inputField"
                  title={"Name"}
                  value={formFields.name.value}
                  attrName={"name"}
                  value_update={updateFieldsState}
                  warn_status={formFields.name.warn_status}
                  placeholder="Enter your full name as per pan"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={formFields.email.value}
                  attrName={"email"}
                  value_update={updateFieldsState}
                  warn_status={formFields.email.warn_status}
                  placeholder="Enter Email"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Mobile Number"}
                  value={formFields.mobile.value}
                  attrName={"mobile"}
                  value_update={updateFieldsState}
                  warn_status={formFields.mobile.warn_status}
                  placeholder="Enter Mobile Number"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Pincode"}
                  value={formFields.pincode.value}
                  attrName={"pincode"}
                  value_update={updateFieldsState}
                  warn_status={formFields.pincode.warn_status}
                  placeholder="Enter Pincode"
                  required={true}
                />
                <CustomButton
                  variant="contained"
                  text="Submit"
                  className="primaryBtn"
                />
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="insurance-benefit res_Section">
        <Grid className="row" container columnSpacing={3}>
          <Grid md={12} sm={12}>
            <Box className="secHead">
              <h2 className="mb-3">
                Groww Insurance <span>Benefits</span>
              </h2>
              <p>
                Analyze the requirement and sell insurance and financial
                products to earn income.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid
          className="row mt-3"
          columnSpacing={3}
          rowSpacing={{ xs: 3 }}
          container
          justifyContent="center"
        >
          {benefits.map((d, i) => (
            <Grid md={4} sm={4} key={i}>
              <Box className="benefitsBox">
                <Box className="imgbox">
                  <img
                    src={d.icon}
                    alt={d.heading}
                    style={{ maxWidth: "43px" }}
                  />
                </Box>
                <h4>{d.heading}</h4>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className="criteria res_Section">
        <Grid className="row" container columnSpacing={3}>
          <Grid md={12} sm={12}>
            <Box className="secHead">
              <h2 className="mb-3">
                Eligibility criteria to become a POSP Advisor for{" "}
                <span>GrowwInsurance</span>
              </h2>
              <p>
                You must qualify for the set eligibility criteria from IRDAI to
                become a Groww Insurance POSP advisor.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid
          className="row mt-6"
          container
          columnSpacing={3}
          alignItems="center"
        >
          <Grid md={6} sm={6}>
            <ul className="criteriaList">
              {criteria.map((d, i) => (
                <li>
                  <span>
                    <CheckIcon />
                  </span>
                  {d}
                </li>
              ))}
            </ul>
          </Grid>
          <Grid md={6} sm={6}>
            <Box className="imgbox" textAlign="center">
              <img
                src="./images/criteria-img.svg"
                alt="criteria-img"
                style={{ maxWidth: "302px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="advisor-steps res_Section">
        <Grid className="row" container columnSpacing={3}>
          <Grid md={12}>
            <Box className="secHead">
              <h2>
                Become POSP Advisor in <span>4 Simple Steps</span>
              </h2>
            </Box>
          </Grid>
        </Grid>
        <Grid
          className="row advisorRow"
          container
          columnSpacing={3}
          rowSpacing={{ xs: 3 }}
        >
          {advisor.map((d, i) => {
            let count = i + 1;
            return (
              <Grid md={3} sm={6} key={i}>
                <Box className="advisorStep">
                  <Box className="stepHead">
                    <h5 className="mb-3">
                      {count.toString().padStart(2, "0")}
                    </h5>
                    <h3 className="bold">{d.heading}</h3>
                  </Box>
                  <p>{d.desc}</p>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box className="product-portfolio res_Section">
        <Grid className="row" container columnSpacing={3}>
          <Grid md={12} xs={12}>
            <Box className="secHead">
              <h2>
                Our Product <span>Portfolio</span>
              </h2>
              <p className="h3-medium">Multiple Products in Single Platform</p>
            </Box>
          </Grid>
        </Grid>
        <Grid className="row mt-6" container columnSpacing={3}>
          {portfolio.map((d, i) => {
            return (
              <Grid md={6} key={i}>
                <Box className="portfolioBox">
                  <Box className="imgbox">
                    <img
                      src={d.icon}
                      alt={d.heading}
                      style={{ maxWidth: "38px" }}
                    />
                  </Box>
                  <h3 className="bold">{d.heading}</h3>
                  <p>{d.desc}</p>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box className="whychoose-posp res_Section">
        <Grid className="row" container columnSpacing={3}>
          <Grid md={5}>
            <h2>Why choosing a POSP Advisor is the best career</h2>
            <Box className="imgbox" textAlign={"center"}>
              <img
                src="./images/whychoose.svg"
                alt="whychoose-img"
                style={{ maxWidth: "370px" }}
              />
            </Box>
          </Grid>
          <Grid md={7}>
            <Box className="col4box">
              {whychooseList.map((d, i) => {
                let count = i + 1;
                return (
                  <Box key={i} className="whiteBoxauto">
                    <h4>{count.toString().padStart(2, "0")}</h4>
                    <p className="lightp">{d}</p>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="smart-tech res_Section">
        <Grid className="row" container columnSpacing={3} alignItems={"center"}>
          <Grid md={6} sm={6} xs={12}>
            <iframe
              src="https://www.youtube.com/embed/yAoLSRbwxL8"
              style={{ height: "295px", width: "100%", borderRadius: "20px" }}
            ></iframe>
          </Grid>
          <Grid md={6} sm={6} xs={12}>
            <Box className="secHead">
              <h3>
                Smart Tech, <span>Right Advice</span>
              </h3>
              <p className="h3-medium lightp">
                Check out the fantastic experience of our POSP Advisors
              </p>
            </Box>
            <Box className="techAdvise">
              <Slider {...adviseSlidersettings}>
                {techAdvise?.map((data, index) => (
                  <Box>
                    <Box className="techbox" margin={"0 15px"}>
                      <Box className="techinfo" key={index}>
                        <img
                          src={data.techimg}
                          width="100%"
                          alt="img"
                          style={{ maxWidth: "77px" }}
                        />
                        <p>
                          {data.techname}
                          <span className="mt-2">{data.designation}</span>
                        </p>
                      </Box>
                      <Box className="advise">
                        <p className="lightp">{data.advise}</p>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="faq-section res_Section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className="secHead">
              <h2>
                <span>Frequently Asked Questions</span> (FAQ)
              </h2>
              {/* <Box className="headBtn">
                <CustomButton
                  variant="contained"
                  className={activeBtn1 ? "primaryBtn light" : "secondaryBtn"}
                  text="Health Insurance"
                  onClick={toggleBtn1}
                />
                <CustomButton
                  variant="outlined"
                  className={activeBtn2 ? "primaryBtn light" : "secondaryBtn"}
                  text="Car Insurance"
                  onClick={toggleBtn2}
                />
              </Box> */}
            </Box>
            <FAQ data={faqs} />
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default BecomeAdvisor;
