import React from "react";
import useMobile from "../../../SupportingFiles/MobileProvider";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function TermAndConditions() {
  const isMobile = useMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="policy-header termsheader res_Section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <h1>Terms & Conditions</h1>
          </Grid>
        </Grid>
      </Box>
      <Box className="textContent-pages res_Section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nec massa nec eros luctus aliquam sit amet a mauris. Ut arcu
              metus, gravida sit amet mauris vitae, iaculis interdum sem. Cras
              consequat tristique purus. Maecenas porta purus quam, consectetur
              pulvinar mauris efficitur a. Phasellus non ante vitae ante tempus
              euismod. Sed nec dictum sapien. Duis hendrerit vehicula arcu.
              Etiam et egestas nulla, eu blandit ligula. Sed tempor suscipit
              sodales. Nam eu malesuada felis. Mauris ut aliquam lorem.
              Phasellus scelerisque neque enim, at imperdiet neque sollicitudin
              at. Duis dictum elementum libero, sed vulputate enim feugiat a.
            </p>
            <p>
              Fusce porttitor ex non lectus pellentesque commodo. Donec id augue
              fringilla, gravida ligula quis, sodales erat. Mauris scelerisque
              convallis feugiat. Mauris pretium vestibulum justo vitae sagittis.
              Sed quis consectetur neque. Nam laoreet, neque at fringilla
              vestibulum, ipsum mi semper augue, quis hendrerit nunc lacus in
              ex. Nulla facilisi. Fusce ultrices nunc enim, sit amet tincidunt
              urna posuere ac. Aenean convallis neque non massa volutpat, at
              sollicitudin arcu consequat.
            </p>
            <p>
              Nunc auctor aliquet orci eget bibendum. Aenean nunc urna,
              vulputate et arcu eu, congue faucibus risus. Curabitur quis ligula
              ac felis ultrices efficitur. Nullam aliquam neque nec risus auctor
              suscipit. Pellentesque lobortis facilisis commodo. Vivamus vitae
              sem faucibus ex pharetra ullamcorper ut vel ante. Vivamus sed
              pulvinar nisl.
            </p>
            <p>
              Duis lorem nibh, tempor vitae accumsan nec, aliquet et erat. Nunc
              dignissim placerat libero non tincidunt. Ut vulputate, magna vel
              tristique convallis, nunc dolor suscipit sapien, ut tincidunt
              justo sem sed turpis. Pellentesque convallis sed dolor dapibus
              tempor. Praesent condimentum euismod arcu, at fermentum felis
              scelerisque ut. Nam vel lectus nec lacus cursus vulputate.
              Pellentesque viverra tincidunt est, quis vulputate nisl.
              Pellentesque gravida feugiat hendrerit. Duis luctus venenatis
              condimentum. Sed urna est, rutrum sagittis commodo et, scelerisque
              eu justo. Sed feugiat pharetra ipsum ac varius. Sed massa elit,
              vehicula vitae accumsan eu, tincidunt et est.
            </p>
            <p>
              Morbi molestie massa et velit scelerisque, at porttitor tellus
              condimentum. Aliquam sit amet vulputate sapien. Donec malesuada
              lacus sit amet varius maximus. Aliquam pellentesque libero in dui
              porttitor vehicula. Aliquam tristique suscipit sapien sit amet
              finibus. Etiam at tempus nibh. Donec elit leo, auctor id erat
              quis, semper imperdiet felis. Vestibulum porttitor aliquet nunc,
              vitae tempor erat maximus et. Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Aliquam rutrum sem sit amet velit ullamcorper mattis. Aenean a
              tristique turpis.
            </p>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermAndConditions;
