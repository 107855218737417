import React from "react";
import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import "./MFooter.scss";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { redirect, useNavigate } from "react-router-dom";

function MFooter() {
  const socialicons: Record<string, string> = {
    facebook: "./images/facebook.svg",
    twitter: "./images/twitter.svg",
    instagram: "./images/instagram.svg",
    linkedin: "./images/linkedin.svg",
    youtube: "./images/youtube.svg",
  };
  const navigate = useNavigate();
  return (
    <Box className="mfooter">
      <Grid container columnSpacing={3} rowSpacing={3} className="row f1">
        <Grid md={3}>
          <Box className="companyDetail">
            <Box className="imgbox">
              <img
                src="./images/logo.svg"
                alt=""
                style={{ maxWidth: "214px" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(COMMON_ROUTES.HOME);
                }}
              />
            </Box>
            <p>
              GrowwInsurance simplifies insurance & financial products and makes
              them accessible for its customers through our digitally enabled
              advisors.
            </p>
            <Box className="follow">
              <h4>Follow Us On</h4>
              <ul>
                {Object.keys(socialicons).map((key) => (
                  <li>
                    <Link>
                      <img key={key} src={socialicons[key]} alt={key} />
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Grid>
        <Grid md={3} xs={6}>
          <h4 className="footerHead">Health Insurance</h4>
          <ul className="footerLinks">
            <li>
              <Link>Family Health Insurance</Link>
            </li>
            <li>
              <Link>Senior Citizen Health Insurance</Link>
            </li>
            <li>
              <Link>Individual Health Insurance</Link>
            </li>
            <li>
              <Link>Compare Health Insurance</Link>
            </li>
            <li>
              <Link>Health Insurance Calculator</Link>
            </li>
            <li>
              <Link>Health Insurance Companies in india</Link>
            </li>
            <li>
              <Link>Network Hospitals</Link>
            </li>
          </ul>
        </Grid>
        <Grid md={3} xs={6}>
          <h4 className="footerHead">Life Insurance</h4>
          <ul className="footerLinks">
            <li>
              <Link>Term Insurance Claim</Link>
            </li>
            <li>
              <Link>Term Life Insurance With Spouse</Link>
            </li>
            <li>
              <Link>Life Insurance Claim Settlement Ratio</Link>
            </li>
            <li>
              <Link>Life Insurance Companies In India</Link>
            </li>
            <li>
              <Link>Term Insurance</Link>
            </li>
            <li>
              <Link>ULIP - Unit Linked Insurance Plan</Link>
            </li>
          </ul>
        </Grid>
        <Grid md={3} xs={12}>
          <h4 className="footerHead">Others</h4>
          <ul className="footerLinks other">
            <li>
              <Link>Become An Advisor</Link>
            </li>
            <li>
              <Link>Claim Support Numbers </Link>
            </li>
            <li>
              <Link>Partner Login</Link>
            </li>
            <li>
              <Link>One Click Renewal</Link>
            </li>
            <li>
              <Link>Podcast</Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIM);
                }}
              >
                Claim
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.COMPLAINT);
                }}
              >
                Complaint
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid columnSpacing={3} container className="row">
        <Grid xs={12}>
          <Box className="borderBox">
            <ul className="footerLinks fmenu">
              <li>
                <Link>Articles</Link>
              </li>
              <li>
                <Link>Press</Link>
              </li>
              <li>
                <Link>Careers</Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link>Contact Us </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRIVACY);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.TERMS);
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.DISCLAIMER);
                  }}
                >
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link>Company Disclosure</Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.SITEMAP);
                  }}
                >
                  Sitemap
                </Link>
              </li>
            </ul>
          </Box>
          <Box className="borderBox">
            <p>
              D2C Consulting Services Private Limited (CIN
              U74900DL2012PTC246820) Principal Place Of Business: 2nd Floor,
              Plot 94, Sector 32 Gurugram 122001, Registered Office: 1st Floor,
              5B/14, Tilak Nagar, New Delhi – 110018., India
            </p>
          </Box>
          <Box className="borderBox">
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid md={6}>
                <p>D2C Consulting Services Private Limited</p>
              </Grid>
              <Grid md={6} className="copyRight">
                <p>
                  © Copyright 2024 Groww Insurance.com | All Rights Reserved.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MFooter;
