import React from "react";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useMobile from "../../../SupportingFiles/MobileProvider";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import { useState } from "react";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";

function Complaint() {
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claim: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claim: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  const claimfor = [
    {
      label: "Health Insurance",
      value: "health",
    },
    {
      label: "Term Insurance",
      value: "term",
    },
  ];
  const message = [
    {
      label: "Health Insurance",
      value: "health",
    },
  ];

  const isMobile = useMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="product-sec product-head">
        <Grid className="row" container columnSpacing={3}>
          <Grid xs={12}>
            <h2 style={{ textAlign: "center" }} className="mb-4">
              Having <span>Any Issue ?</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              elementum tortor commodo, sodales erat eget, fringilla metus. In
              quis risus sem. Nullam sit amet orci libero. Sed laoreet rutrum
              augue vitae congue. Phasellus vitae dui id turpis eleifend pretium
              vitae facilisis quam. Suspendisse ut risus neque. Nullam ultricies
              nisl ac augue pellentesque, sit amet pharetra nisi molestie.
              Suspendisse viverra augue vel dui lacinia, vitae viverra purus
              aliquam. Proin quis viverra orci.<p></p> Sed imperdiet rhoncus
              ante, eget commodo augue pulvinar in. Quisque pulvinar mauris
              volutpat ipsum interdum aliquam. Vivamus interdum risus ut quam
              ultrices, in facilisis felis suscipit. Phasellus rutrum mi vitae
              odio convallis scelerisque. Nullam ac augue vehicula, accumsan
              enim at, dapibus nibh. Nunc tempus dolor tortor, quis congue justo
              ultrices vitae. Vestibulum in porttitor nibh.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="product-sec product-formsection">
        <Grid className="row" container columnSpacing={3}>
          <Grid sm={6}>
            <img
              src="images/health-product.svg"
              alt="healthimg"
              style={{ maxWidth: "505px" }}
            />
          </Grid>
          <Grid sm={6}>
            <Box className="productForm">
              <h4 style={{ textAlign: "center" }}>
                Select the family members you want to insure
              </h4>
              <form action="">
                <RKTextField
                  class_name="inputField"
                  title={"Name"}
                  value={formFields.name.value}
                  attrName={"name"}
                  value_update={updateFieldsState}
                  warn_status={formFields.name.warn_status}
                  placeholder="Enter Full Name"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={formFields.mobile.value}
                  attrName={"mobile"}
                  value_update={updateFieldsState}
                  warn_status={formFields.mobile.warn_status}
                  placeholder="Enter Mobile Number"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={formFields.email.value}
                  attrName={"email"}
                  value_update={updateFieldsState}
                  warn_status={formFields.email.warn_status}
                  placeholder="Enter Email"
                  required={true}
                />
                <TextArea
                  class_name="inputField mb-6"
                  title={"Message"}
                  value={formFields.message.value}
                  attrName={"message"}
                  value_update={updateFieldsState}
                  warn_status={formFields.message.warn_status}
                  placeholder="Write here..."
                />
                <CustomButton
                  text={"Submit"}
                  className="primaryBtn"
                  variant={"contained"}
                />
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Complaint;
