import React from "react";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useMobile from "../../../SupportingFiles/MobileProvider";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { useState } from "react";

function HealthProductPage() {
  const isMobile = useMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claim: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claim: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="product-sec product-head">
        <Grid className="row" container columnSpacing={3}>
          <Grid xs={12}>
            <h2 style={{ textAlign: "center" }} className="mb-4">
              The Importance of <span>Health Insurance</span>
            </h2>
            <p>
              The world around us,is changing very fast, like Life style,
              climate, habits, work culture, Medical & Technological advancement
              and very fastly we are adopting to Nuclear family culture,all
              these made Health Insurance as the most importance insurance
              product to have when you are alive. The health insurance industry
              in India has undergone a sea-change over the last two years. One
              major change has been the perception of the category amongst
              customers, and the need to have a health insurance policy. The
              pandemic has made everyone realise the uncertainties of life and
              their unpreparedness in case of any health-related emergency.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="product-sec product-formsection">
        <Grid className="row" container columnSpacing={3}>
          <Grid sm={6}>
            <h3>
              Secure <span>your</span> well-being <span>with our</span> health
              coverage.
            </h3>
            <img
              src="images/health-product.svg"
              alt="healthimg"
              style={{ maxWidth: "505px" }}
            />
          </Grid>
          <Grid sm={6}>
            <Box className="productForm">
              <h4 style={{ textAlign: "center" }}>
                Select the family members you want to insure
              </h4>
              <form action="">
                <RKTextField
                  class_name="inputField"
                  title={"Name"}
                  value={formFields.name.value}
                  attrName={"name"}
                  value_update={updateFieldsState}
                  warn_status={formFields.name.warn_status}
                  placeholder="Enter Full Name"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={formFields.mobile.value}
                  attrName={"mobile"}
                  value_update={updateFieldsState}
                  warn_status={formFields.mobile.warn_status}
                  placeholder="Enter Mobile Number"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={formFields.email.value}
                  attrName={"email"}
                  value_update={updateFieldsState}
                  warn_status={formFields.email.warn_status}
                  placeholder="Enter Email"
                  required={true}
                />
                <TextArea
                  class_name="inputField mb-6"
                  title={"Message"}
                  value={formFields.message.value}
                  attrName={"message"}
                  value_update={updateFieldsState}
                  warn_status={formFields.message.warn_status}
                  placeholder="Write here..."
                />
                <CustomButton
                  text={"Submit"}
                  className="primaryBtn"
                  variant={"contained"}
                />
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default HealthProductPage;
