export enum COMMON_ROUTES {
  HOME = "/",
  CONTACT = "/contact-us",
  ABOUT = "/about-us",
  ADVISOR = "/become-an-advisor",
  PRIVACY = "/privacy-policy",
  TERMS = "/terms-and-conditions",
  DISCLAIMER = "/disclaimer",
  SITEMAP = "/sitemap",
  PRODUCT_HEALTH = "/health-insurance",
  PRODUCT_TERM = "/term-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  CLAIM = "/claim",
  COMPLAINT = "/complaint",
  HEALTH_FORM = "/form/health-insurance-form",
  CAR_FORM = "/form/car-insurance-form",
  TW_FORM = "/form/two-wheeler-form",
  TERM_FORM = "/form/term-insurance-form",
}
