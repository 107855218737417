import React from "react";
import "./CustomCard.scss";
import { Box, Link, Button } from "@mui/material";
import EastRoundedIcon from "@mui/icons-material/EastRounded";

function CustomCard({
  blogimg,
  blogtitle,
  blogDes,
}: {
  blogimg: string;
  blogtitle: string;
  blogDes: string;
}) {
  return (
    <Box className="card" style={{ margin: "0 12px" }}>
      <Box className="imgbox">
        <img src={blogimg} alt={blogtitle} />
      </Box>
      <Box className="blogDesc">
        <h4>{blogtitle}</h4>
        <h5>{blogDes}</h5>
        <Box className="action">
          <Link className="readbtn">
            Read more
            <span>
              <EastRoundedIcon />
            </span>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomCard;
