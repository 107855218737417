import React from "react";
import useMobile from "../../../SupportingFiles/MobileProvider";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";

function Disclaimer() {
  const isMobile = useMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="policy-header termsheader res_Section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <h1>Disclaimer</h1>
          </Grid>
        </Grid>
      </Box>
      <Box className="textContent-pages res_Section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={12}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nec massa nec eros luctus aliquam sit amet a mauris. Ut arcu
              metus, gravida sit amet mauris vitae, iaculis interdum sem. Cras
              consequat tristique purus. Maecenas porta purus quam, consectetur
              pulvinar mauris efficitur a. Phasellus non ante vitae ante tempus
              euismod. Sed nec dictum sapien. Duis hendrerit vehicula arcu.
              Etiam et egestas nulla, eu blandit ligula. Sed tempor suscipit
              sodales. Nam eu malesuada felis. Mauris ut aliquam lorem.
              Phasellus scelerisque neque enim, at imperdiet neque sollicitudin
              at. Duis dictum elementum libero, sed vulputate enim feugiat a.
            </p>
            <p>
              Fusce porttitor ex non lectus pellentesque commodo. Donec id augue
              fringilla, gravida ligula quis, sodales erat. Mauris scelerisque
              convallis feugiat. Mauris pretium vestibulum justo vitae sagittis.
              Sed quis consectetur neque. Nam laoreet, neque at fringilla
              vestibulum, ipsum mi semper augue, quis hendrerit nunc lacus in
              ex. Nulla facilisi. Fusce ultrices nunc enim, sit amet tincidunt
              urna posuere ac. Aenean convallis neque non massa volutpat, at
              sollicitudin arcu consequat.
            </p>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Disclaimer;
