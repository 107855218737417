import React from "react";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useMobile from "../../../SupportingFiles/MobileProvider";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { useState } from "react";

function TermProductPage() {
  const isMobile = useMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claim: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claim: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="product-sec product-head">
        <Grid className="row" container columnSpacing={3}>
          <Grid sm={12}>
            <h2 style={{ textAlign: "center" }} className="mb-4">
              The Importance of <span>Term Insurance</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              scelerisque tincidunt enim at cursus. Integer in leo suscipit,
              consequat mi sit amet, egestas felis. Sed ac turpis vel dolor
              posuere ultrices. Vestibulum eu pulvinar ex. Aenean in bibendum
              mauris. Donec sodales sagittis purus, consequat porta felis
              consequat et.
            </p>
            <p>
              Maecenas sollicitudin magna ac arcu auctor, ac facilisis felis
              pharetra. Mauris sodales euismod semper. Vestibulum ante ipsum
              primis in faucibus orci luctus et ultrices posuere cubilia curae;
              Donec in dapibus dui. Aliquam malesuada, nisi eget convallis
              tempor, diam tortor eleifend magna, sit amet consectetur lacus
              odio eleifend arcu. Ut vel varius eros. Maecenas vel felis ut quam
              iaculis consectetur. Donec vitae dapibus sapien, at pharetra
              tortor. Sed lacinia nibh ipsum, eu ultrices magna placerat a.
              Nulla quis lacus eget libero tempor finibus quis sit amet metus.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="product-sec product-formsection">
        <Grid className="row" container columnSpacing={3}>
          <Grid sm={6}>
            <h3>
              Secure <span>your</span> well-being <span>with our</span> term
              coverage.
            </h3>
            <img
              src="images/health-product.svg"
              alt="healthimg"
              style={{ maxWidth: "505px" }}
            />
          </Grid>
          <Grid sm={6}>
            <Box className="productForm">
              <h4 style={{ textAlign: "center" }}>
                Select the family members you want to insure
              </h4>
              <form action="">
                <RKTextField
                  class_name="inputField"
                  title={"Name"}
                  value={formFields.name.value}
                  attrName={"name"}
                  value_update={updateFieldsState}
                  warn_status={formFields.name.warn_status}
                  placeholder="Enter Full Name"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Mobile"}
                  value={formFields.mobile.value}
                  attrName={"mobile"}
                  value_update={updateFieldsState}
                  warn_status={formFields.mobile.warn_status}
                  placeholder="Enter Mobile Number"
                  required={true}
                />
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={formFields.email.value}
                  attrName={"email"}
                  value_update={updateFieldsState}
                  warn_status={formFields.email.warn_status}
                  placeholder="Enter Email"
                  required={true}
                />
                <TextArea
                  class_name="inputField mb-6"
                  title={"Message"}
                  value={formFields.message.value}
                  attrName={"message"}
                  value_update={updateFieldsState}
                  warn_status={formFields.message.warn_status}
                  placeholder="Write here..."
                />
                <CustomButton
                  text={"Submit"}
                  className="primaryBtn"
                  variant={"contained"}
                />
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermProductPage;
