import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavBar.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";

function MNavBar() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenMenu(open);
    };
  return (
    <Box className="mnavbar">
      <Grid columnSpacing={3} className="row" container>
        <Grid xs={6}>
          <img
            src="./images/logo.svg"
            height="44px"
            alt="Groww Insurance"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(COMMON_ROUTES.HOME);
            }}
            style={{ cursor: "pointer", maxWidth: "244px" }}
          />
        </Grid>
        <Grid xs={6}>
          <Box className="menucontainer">
            <Link
              className="hamburger-menu"
              onClick={toggleDrawer("left", true)}
            >
              <img
                src="./images/menu_icon.svg"
                alt="menuicon"
                style={{
                  maxWidth: "25px",
                }}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT);
                  }}
                >
                  About Us
                </Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section">
            <p className="menu-heading">Insurance</p>
            <ul className="subMenu">
              <li>
                <Link className="nav-link">Car Insurance</Link>
                <Link className="nav-link">Term Insurance</Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section pt-0">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(COMMON_ROUTES.CONTACT);
                  }}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => {
                    navigate(COMMON_ROUTES.ADVISOR);
                  }}
                >
                  Become an Advisor
                </Link>
              </li>
              <li>
                <Link className="nav-link">login</Link>
              </li>
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
