import React from "react";
import useMobile from "../../../SupportingFiles/MobileProvider";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

function SiteMap() {
  const isMobile = useMobile();
  const navigate = useNavigate();

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="policy-header termsheader res_Section">
        <Grid className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h1>SiteMap</h1>
          </Grid>
        </Grid>
      </Box>
      <Box className="textContent-pages res_Section">
        <Grid className="row" columnSpacing={3} container>
          <Grid xs={6}>
            <Box>
              <h4 className="siteHead">Main Pages</h4>
              <ul className="siteUl">
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.HOME);
                    }}
                  >
                    <KeyboardDoubleArrowRightRoundedIcon />
                    Home
                  </Link>
                </li>
                <li>
                  <Link>
                    <KeyboardDoubleArrowRightRoundedIcon />
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.CONTACT);
                    }}
                  >
                    <KeyboardDoubleArrowRightRoundedIcon />
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.ADVISOR);
                    }}
                  >
                    <KeyboardDoubleArrowRightRoundedIcon />
                    Become Advisor
                  </Link>
                </li>
                <li>
                  <Link>
                    <KeyboardDoubleArrowRightRoundedIcon />
                    login
                  </Link>
                </li>
              </ul>
            </Box>
            <Box className="mt-6">
              <h4 className="siteHead">Insurance Products</h4>
              <ul className="siteUl">
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                    }}
                  >
                    <KeyboardDoubleArrowRightRoundedIcon />
                    Health Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_TERM);
                    }}
                  >
                    <KeyboardDoubleArrowRightRoundedIcon />
                    Term Insurance
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default SiteMap;
