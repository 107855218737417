import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeContainer from "../../Container/HomeContainer/HomeContainer";
import { COMMON_ROUTES } from "../Path/CommonRoutes";
import Contact from "../../Page/ResponsivePages/Contact/Contact";
import BecomeAdvisor from "../../Page/ResponsivePages/BecomeAdvisor/BecomeAdvisor";
import PrivacyPolicy from "../../Page/ResponsivePages/PrivacyPolicy/PrivacyPolicy";
import TermAndConditions from "../../Page/ResponsivePages/TermAndConditions/TermAndConditions";
import Disclaimer from "../../Page/ResponsivePages/Disclaimer/Disclaimer";
import SiteMap from "../../Page/ResponsivePages/SiteMap/SiteMap";
import HealthProductPage from "../../Page/ResponsivePages/HealthProductPage/HealthProductPage";
import CarProductPage from "../../Page/ResponsivePages/CarProductPage/CarProductPage";
import TermProductPage from "../../Page/ResponsivePages/TermProductPage/TermProductPage";
import Claim from "../../Page/ResponsivePages/Claim/Claim";
import Complaint from "../../Page/ResponsivePages/Complaint/Complaint";
import About from "../../Page/ResponsivePages/About/About";

function CommonRoutes() {
  return (
    <div className="App">
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.CONTACT} element={<Contact />} />
        <Route path={COMMON_ROUTES.ABOUT} element={<About />} />
        <Route path={COMMON_ROUTES.ADVISOR} element={<BecomeAdvisor />} />
        <Route path={COMMON_ROUTES.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={COMMON_ROUTES.TERMS} element={<TermAndConditions />} />
        <Route path={COMMON_ROUTES.DISCLAIMER} element={<Disclaimer />} />
        <Route path={COMMON_ROUTES.SITEMAP} element={<SiteMap />} />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_TERM}
          element={<TermProductPage />}
        />
        <Route path={COMMON_ROUTES.CLAIM} element={<Claim />} />
        <Route path={COMMON_ROUTES.COMPLAINT} element={<Complaint />} />
      </Routes>
    </div>
  );
}

export default CommonRoutes;
