import React from "react";
import Home from "../../Page/Desktop/Home/Home";
import MobHome from "../../Page/Mobile/MobHome";
import useMobile from "../../SupportingFiles/MobileProvider";

function HomeContainer() {
  const isMobile = useMobile();

  return <>{isMobile ? <MobHome /> : <Home />}</>;
}

export default HomeContainer;
