import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./FAQ.scss";

const FAQ = ({ data }: { data: { title: string; desc: any }[] }) => {
  const [expanded, setExpanded] = React.useState<string | false>("-1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box className="faq">
      {data.map((data, index) => (
        <Accordion
          expanded={expanded === `${index}`}
          onChange={handleChange(`${index}`)}
        >
          <AccordionSummary
            expandIcon={expanded === `${index}` ? <RemoveIcon /> : <AddIcon />}
            aria-controls={`${index}` + "bh-content"}
            id={`${index}` + "bh-header"}
          >
            <Typography>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.desc}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
